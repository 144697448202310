.sertificates_descr{
    background-color: #98A6B2;
    padding: 261px 0 202px 0;
    position: relative;
}   

.top_rectangle{
    position: absolute;
    top: -0.2%;
    width: 100%;
}

.bottom_rectangle{ 
    position: absolute;
    bottom: -0.2%;
    width: 100%;
}

.sertificates_descr_inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sertificates_descr_text{
    
    color: #FFFFFF;
    max-width: 715px;
}

.sertificates_descr_subtitle{
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 22px;
    line-height: 23px;
}

.sertificates_descr_paragraph{
    margin-bottom: 45px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
}

.sertificates_descr_russia{
    margin-top: -45px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    max-width: 630px;
    padding-right: 170px;
    background: url(../assets/icons/nopriz-rus.svg) no-repeat right bottom/150px;
}

@media(max-width:1199px){
    .sertificates_descr_russia{
        padding-right: 165px;
    }
}

@media(max-width:991px){
    .sertificates_descr_russia{
        padding-right:120px;
        background-size: 110px;
    }
}

@media(max-width:767px){  
    .sertificates_descr{
        padding: 92px 0 54px 0;
    }   
    .sertificates_descr_inner{
        display: block;
    }
    .slider{
        display: block !important;
    }
    .sertificates_descr_text{
        margin-top: 81px;
    }
    .sertificates_descr_subtitle{
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
    }
    .sertificates_descr_paragraph{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 32px;
    }
    .sertificates_descr_russia{
        margin-top: -32px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 32px;
    }
}

@media(max-width:575px){
    .sertificates_descr_russia{
        padding-right: 90px;
        background-size: 80px;
    }
}