.footer {
    padding-top: 118px;
    background-color: #0a4689;
}

footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.logo {
    margin-left: 80px;
    display: block;
    width: 163px;
}

.logo img {
    width: 100%;
}

.footer_top {
    margin-top: 38px;
    display: flex;
    justify-content: space-between;
}

.footer_top a {
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.footer_top_link {
    background: url(../assets/icons/map-icon.svg) no-repeat left top;
    padding-left: 80px;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    text-decoration: none;
}

.footer_top_link p {
    color: #fff;
    text-decoration: none;
}

.footer_top_btn {
    background: url(../assets/icons/map-icon.svg) no-repeat left top;
    padding-left: 80px;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.map {
    background: url(../assets/icons/map-icon-2.svg) no-repeat left top;
    display: block;
    max-width: 448px;
}

.phone {
    background: url(../assets/icons/footer-phone-icon-2.svg) no-repeat left top;
}

.letter {
    background: url(../assets/icons/footer-letter-icon-2.svg) no-repeat left top;
}

.footer_middle {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 80px;
    margin-top: 30px;
}

.footer_middle_link {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: #fff;
}

.footerMiddle {
    margin-top: 30px;
    margin-left: 77px;
}

.footerMiddle a {
    font-weight: 600;
    font-size: 12.5px;
    line-height: 17px;
}

.footer_bottom {
    margin-top: 35px;
    background-color: #7894b4;
    padding: 28px 0;
}

.footer_bottom_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyright {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: white;
    text-align: center;
    margin: 0;
}

@media (max-width: 991px) {
    .logo {
        margin-left: 50px;
    }
    .footer_top_link {
        padding-left: 50px;
    }
    .map {
        max-width: 265px;
        padding-bottom: 0;
    }
    .footer_middle {
        margin-left: 0;
    }
    .footer_middle_link {
        font-size: 13px;
    }
}

@media (max-width: 767px) {
    .footer {
        padding-top: 62px;
    }
    .logo {
        margin: 0 auto;
    }
    .footer_top {
        flex-direction: column;
    }
    .footer_top_link {
        padding: 0 0 0 33px;
        font-size: 13px;
        line-height: 18px;
    }
    .footer_top_btn {
        padding: 0 0 0 33px;
        margin-top: 10px;
    }
    .phone {
        background-size: 23px;
        background-position: left center;
    }
    .letter {
        background-size: 23px;
        background-position: left center;
    }
    .map {
        max-width: 307px;
        background-size: 19px;
        background-position: left 4px;
    }
    .footer_middle {
        flex-direction: column;
        justify-content: space-between;
        margin-top: 32px;
    }
    .footer_middle_link {
        line-height: 24px;
    }
    .footer_bottom {
        margin-top: 24px;
        padding: 16px 0;
    }
    .copyright {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
    }
}
