.cards{
    padding: 67px 0 102px;
}

.cards_inner{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr 1fr;
    row-gap: 38px;
    column-gap: 45px;
    margin: 0 auto;
    max-width: 955px;
} 

.cards_link{
    box-shadow: 2px 6px 19px -4px #094689;
    border-radius: 13px;
    height: 190px;
    padding: 31px 0 45px 0;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #005A92;
    text-align: center;
}

.cards_link img{
    max-width: 100%;
}

.cards_link span{
    height: 51px;
    display: flex;
    align-items: center;
} 

.cards_link:hover {
    background-color: #FFDA6B;;
}

@media(max-width:991px){
    .cards_link{
        height: 160px;
        padding: 20px 0 30px 0;
    }
}

@media(max-width:767px){
    .cards{
        padding: 50px 0 72px;
    }
    .cards_inner{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4,1fr);
        row-gap: 24px;
        column-gap: 32px;
        max-width: 295px;
    }
    .cards_link{
        height: 122px;
        padding: 12px 0 15px 0;
        line-height: 14px;
        font-size: 13px;
    }
    .history{
        width: 52px;
    }
    .about_company{
        width: 49px;
    }
    .projects{
        width: 53px;
    }
    .partners{
        width: 67px;
    }
    .management{
        width: 42px;
    }
    .resources{
        width: 39px;
    }
    .sertificates{
        width: 35px;
    }
    .career{
        width: 36px;
    }
}
