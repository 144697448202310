.sertificates_descr{
    padding: 123px 0;
    background-color: #F0F7FC;
    position: relative;
}   

.bottom_rectangle{ 
    position: absolute;
    bottom: -0.2%;
    width: 100%;
}

.sertificates_descr_title{
    font-weight: 700;
    color:#013977;
    margin-bottom: 46px;
    background: url(../assets/icons/arrow-yellow.svg) no-repeat left 1px;
    padding-left: 73px;
    margin-left: -73px; 
    font-size: 18px;
    line-height: 23px;
    padding-bottom: 1px;
    padding-top: 1px;
    text-transform: uppercase;
}

.sertificates_descr_subtitle{
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color:#013977;
    margin-top: 23px;
}

.sertificates_descr_paragraph{
    /* background: url(../assets/icons//ellipse.svg) no-repeat 0 5px;
    padding-left: 40px;
    margin-left: -40px; */
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
    margin-top: 23px;
}

.sertificates_descr_paragraph_2{
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color:#013977;
    margin-top: 23px;
    margin-bottom: 0;
}

@media(max-width:1399px){
    .sertificates_descr_title{
        margin-left: -33px; 
    }
    .sertificates_descr_paragraph{
        margin-left: 0;
    } 
    .sertificates_descr_subtitle{
        margin-left: 40px;
    }
    .sertificates_descr_paragraph_2{
        margin-left: 40px;
    }
}

@media(max-width:575px){
    .sertificates_descr{
        padding: 60px 0;
    }   
    .bottom_rectangle{ 
        bottom: 0;
    }
    .sertificates_descr_inner:last-child{
        margin-bottom: 0;
    }
    .sertificates_descr_item{
        margin-bottom: 32px;
    }
    .sertificates_descr_title{
        margin-left: 0; 
        font-weight: 600;
        margin-bottom: 6px;
        background-size: 24px 24px;
        background-position: left -1px;
        padding-left: 40px;
        padding-bottom:10px;
        font-size: 16px;
        line-height: 21px;
    }
    .sertificates_descr_subtitle{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }
    .sertificates_descr_paragraph{
        background-position: left 1px;
        padding-left: 24px;
        margin-left: 20px;
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
    .sertificates_descr_paragraph_2{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
    }
}
