.history_descr{
    background-color:#0A4689;
    padding: 235px 0 195px 0;
    position: relative;
}

.top_rectangle{
    position: absolute;
    top: -0.2%;
    width: 100%;
}

.bottom_rectangle{
    position: absolute;
    bottom: -0.2%;
    width: 100%;
}

.history_descr_text{
    margin-top: 38px;
}

.history_descr_paragraph{  
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
}

.history_descr_paragraph span{
    font-weight: 700;
}

.history_descr_slider{
    width: 100%;
}

.history_descr_slider img{
    width: 100%;
}

.show_more {
    display: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #fff;
    text-shadow: 2px 1px 40px rgba(0, 0, 0, 0.25);
}


@media(max-width:576px) {
    .history_descr {
        padding: 90px 0 70px 0;
    }
    .history_descr_text {
        text-align: center;
    }
    .history_descr_paragraph {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-shadow: 2px 1px 40px rgba(0, 0, 0, 0.25);
    }
    .history_descr_paragraph span {
        font-weight: 600;
    }
    .show_more {
        display: block;
        margin: 0 auto;
    }
}