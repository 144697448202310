.show_more{
    display: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #094689;
    text-shadow: 2px 1px 40px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 12px;
    padding: 0;
    margin: 12px auto 0 auto;
}

@media(max-width:767px){
    .show_more {
        display: block;
    }
}