.department_management{
    padding: 144px 0 52px 0;
}

.department_management_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 105px;
}

.department_management_text{
    max-width: 527px;
}

.department_management_descr{
    margin-top: 44px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
}

.department_management_title{ 
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-transform: uppercase;
    color: #013977;
    margin-bottom: 0;
}


.department_management_img{
    max-width: 323px;
}

.department_management_img img{
    width: 100%;    
}

@media(max-width:1199px){
    .department_management_inner{
        padding: 0;
    }
}

@media(max-width:767px){
    .department_management{
        padding: 48px 0 20px 0;
    }
    .department_management_inner{
        flex-direction: column-reverse;
    }
    .department_management_text{
        margin-top: 24px;
        text-align: center;
    } 
    .department_management_title{ 
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }
    .department_management_descr{
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
    .department_management_img{
        max-width: 200px;
    }
}



