.management{
    background-color: #F0F7FC;
    padding: 107px 0 264px 0;
    position: relative;
}

.bottom_rectangle{
    position: absolute;
    bottom: -0.1%;
    width: 100%;
}

.management img{
    width: 100%;
}

.management_ceo{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 13.1%;
    margin: 70px auto 0 auto;
}

.management_directors_list{
    margin-top: 70px;
    display: flex;
	flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
}

.management_directors_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 13.1%;
}

.management_directors_item p{
    margin-bottom: 0;
}

.management_subtitle{
    margin-top: 105px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 33px;
    line-height: 43px;
    text-align: center;
    color: #013977;
}

.management_engineers{
    padding: 0 110px;
    margin-top: 73px;
}

.management_engineers_list{
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 0;
    margin: 0;
}

.management_engineers_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 16.3%;
}

.management_engineers_item p{
    margin-bottom: 0;
}

.management_name{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-top: 6px;
    margin-bottom: 0;
}

.management_post{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.management_number{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.management_img{
    width: 166px;
    height: 203px;  
    background: #d1c5c5;
    border-radius: 11px;
}

@media(min-width:1400px){
    .container{
        max-width: 1271px !important;
        padding: 0;
    }
}

@media(max-width:1399px){
    .management_engineers{
        padding: 0 73px;
    }
    .management_engineers_item{
        width: 15.6%;
    }
}

@media(max-width:1199px){
    .management_engineers_item{
        width: 16.2%;
    }
}

@media(max-width:991px){
    .management_ceo{
        width: 14%;
    }
    .management_directors_item{
        width: 14%;
    }
    .management_engineers{
        margin-top: 81px;
        padding: 0 55px;
    }
    .management_engineers_item{
        width: 17.6%;
    }
}

@media(max-width:991px){
    .management_ceo{
        width: 14%;
    }
    .management_directors_item{
        width: 14%;
    }
    .management_engineers{
        margin-top: 81px;
        padding: 0 55px;
    }
    .management_engineers_item{
        width: 17.6%;
    }
}

@media(max-width:767px){
    .management{
        padding: 60px 0 74px 0;
    }
    .management_title{
        font-size: 18px;
        line-height: 23px;
    }
    .management_directors{
        display: none;
    }
    .management_engineers{
        display: none;
    }
    .management_ceo{
        width: 166px;
        margin-top: 32px;
        margin-bottom: 48px;
    }
    .management_directors_item{
        max-width: 200px;
        display: block !important;
        margin: 0 auto;
				width: 45%;
				margin-bottom: 40px;
    }
    .management_engineers_item{
        max-width: 166px;
        display: block !important;
        margin: 0 auto;
    }
    .engineers_title{
        margin-top: 48px;
        margin-bottom: 32px;
        font-size: 18px;
        line-height: 23px;
    }
}