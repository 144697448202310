.industry_item {
    padding: 78px 0 178px 0;
    position: relative;
}

.project:first-child{
    margin-top:  0 !important;
}

.bottom_rectangle {
    position: absolute;
    bottom: -0.1%;
    width: 100%;
}
 
.project { 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #033266;
    margin-top: 47px;
}

.project_left{
    max-width: 55.9%;
}

.project_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0;
}

.project_title p{
    margin-bottom: 0;
}

.project_descr {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 33px;
}

.project_descr p{
    margin-bottom: 8px;
}

.project_right{
    max-width: 41.9%;
    z-index: 1;
    padding: 0;
    border: none;
    background-color: transparent;
}

.showImg{
    cursor: pointer;
}

.project_right img{
    width: 100%;
}

.lightbox{
    z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*optional bg color*/
	background-color: rgba(47, 71, 180, 0.52);
	/*the following isn't necessary but will help center everything once we add buttons*/
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 20px;
	box-sizing: border-box;
	justify-content: center;
}

.lightbox img {
	width: 100%;
	object-fit: contain;
	height: 100%; 
}

@media(max-width:767px){
    .project {
        flex-direction: column;
        margin-top: 45px;
    }
    .project_descr{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-top: 0;
    }
    .project_title {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 16px;
    }
    .project_right{
        max-width: 100%;
    }
    .project_left{
        max-width: 100%;
    }
    .bottom_rectangle {
        bottom: 0%;
    }
}


