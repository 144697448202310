.vacancies{
    padding: 106px 0 288px 0;
    position: relative;
}
 
.bottom_rectangle{
    position: absolute;
    bottom: -0.1%;
    width: 100%;
}

.vacancies_inner{
    padding-left: 34px; 
}

.vacancies_title{
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: #013977;
}

.vacancies_subtitle{
    margin-top: 75px;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #013977;
}

.vacancies_jobs{
    margin-top: 50px;
}

.vacancies_jobs_descr{
    /* background: url(../assets/icons/ellipse.svg) no-repeat 0 center;
    padding-left: 30px;
    margin-left: -30px; */
    margin-top: 23px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
}

.vacancies_department{
    margin-top: 30px;
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #013977;
}

.vacancies_conditions{
    margin-top: 51px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 178px;
}

.vacancies_conditions_descr{
    margin-top: 23px;
    margin-bottom: 0;
}

@media(max-width:575px){
    .vacancies{
        padding: 60px 0 120px 0;
    }
    .bottom_rectangle{
        bottom: -0.03%;
    }
    .vacancies_inner{
        padding-left:0; 
    }
    .vacancies_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    } 
    .vacancies_subtitle{
        margin-top: 24px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }  
    .vacancies_jobs{
        margin-top: 16px;
    }  
    .vacancies_jobs_descr{
        background-size: 16px auto;
        background-position: 0 2px;
        padding-left: 24px;
        margin-left: 0;
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    .vacancies_department{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
        margin-bottom: 0;
    }
    .vacancies_conditions{
        margin-top: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 32px;
    }
    .vacancies_conditions_descr{
        margin-top: 24px;
        margin-bottom: 0;
    }
}