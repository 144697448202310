.header {
    padding: 45.5px 0;
}

.header_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_left {
    display: flex;
    align-items: center;
}
 
.menu {
    position: relative;
}

.menu_container {
    display: none;
    position: absolute;
    width: 266px;
    z-index: 3;
    padding-top: 52px;
}

.menu_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    position: relative;
    cursor: pointer;
    transition: .4s;
}

.menu_icon {
    display: block;
    position: relative;
    background: #094689;
    width: 100%;
    height: 2px;
    transition: .4s;
    padding: 0;
}

.menu_icon::after, .menu_icon::before {
    content: "";
    display: block;
    position: absolute;
    background: #094689;
    width: 100%;
    height: 2px;
    transition: .4s;
}

.menu_icon::after {
    top: 7.7px;
}

.menu_icon::before {
    top: -7.7px;
}

.menu_checkbox {
    display: none;
}

.menu_list {
    list-style-type: none;
    padding: 0;
}

.menu_item {
    padding: 6px 0 6px 23px;
    background: #FFFFFF;
    margin: 0;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.menu_link {
    margin: 0;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #094689;
    display: block;
}

.menu_link_certificates{
    width: 139px;
}

.menu_bottom {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 89px auto;
    align-items: center;
}

.languages_list {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #094689;
    text-transform: uppercase;
}

.social_network_list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: 16px;
}

.social_network_list li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.call {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #094689;
    text-decoration: none;
    margin-top: 24px;
}

.menu:hover .menu_container {
    display: block;
}

.logo {
    width: 163px;
    height: 37px;
    margin-left: 54px;
}

.logo img {
    width: 100%;
    height: 100%;
}

.header_right_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 175px;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header_right_link {
    display: flex;
    align-items: center;
}

.header_right_button {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.select {
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    color: #094689;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    cursor: pointer;
}

.select:focus {
    outline: none;
}

.option {
    font-weight: 500;
}

.modal-title {
    color: #094689;
    width: 100%;
}

.modal-content {
    text-align: center;
}


@media (min-width: 768px) {
    .menu_item:hover {
        background-color: #FFD863
    }
}

@media (max-width: 768px) {
    .header {
        padding: 24px 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9999999;
        background-color: #fff;
    }

    .header_left {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .logo {
        margin: 0;
    }

    .header_right {
        display: none;
    }

    .menu {
        top: 0;
        left: 0;
        right: 0;
    }

    .menu_container {
        position: fixed;
        top: 75px;
        left: 0;
        right: 0;
        height: 0;
        opacity: 1;
        transition: .5s;
        overflow: hidden;
        background-color: #fff;
        padding: 0;
        width: 100%;
    }

    .languages_list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding: 0;
        margin: 52px 0 0 0;
    }

    .languages_btn {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(9, 70, 137, 0.5);;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
    }

    .languages_btn:focus {
        color: #094689;
    }

    .menu_bottom {
        display: flex;
    }

    .menu_list {
        transition: .5s;
        list-style: none;
        padding-left: 16px;
    }

    .menu_item {
        margin-top: 24px;
        border: none;
        padding: 0;
        background: #FFFFFF;
        box-shadow: none;
    }

    .menu_link {
        text-decoration: none;
        color: #094689;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    .menu_checkbox:checked ~ .menu_container {
        height: 100%;
    }

    .menu_checkbox:checked ~ .menu_btn .menu_icon {
        background: transparent;
    }

    .menu_checkbox:checked ~ .menu_btn .menu_icon::before, .menu_checkbox:checked ~ .menu_btn .menu_icon::after {
        top: 0;
    }

    .menu_checkbox:checked ~ .menu_btn .menu_icon::after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .menu_checkbox:checked ~ .menu_btn .menu_icon::before {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}
