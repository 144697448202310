.carousel {
    position: relative;
    overflow: hidden;
    padding-bottom: 0.6%;
}

.slider_bottom {
    position: absolute;
    bottom: 0.6%;
    z-index: 3;
    width: 100%;
}

.slider_left {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 68%;
}

.slider_right {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 74%;
}

.slider_left_lines {
    position: absolute;
    z-index: 2;
    left: -2.2%;
    top: -2%;
    height: 60%;
}

.slider_right_lines {
    position: absolute;
    z-index: 2;
    right: -8%;
    top: -2.9%;
    height: 83%;
}

.button_left {
    background-color: transparent;
    border: none;
    position: absolute;
    z-index: 2;
    top: 34.5%;
    left: 9%;
}

.button_right {
    background-color: transparent;
    border: none;
    position: absolute;
    z-index: 2;
    top: 34.5%;
    right: 11%;
}

.carousel_item_descr {
    position: absolute;
    width: 40%;
    bottom: 70.5%;
    left: 31.5%;
    font-weight: 600;
    font-size: 1.21vw;
    line-height: 2.08vw;
    text-transform: uppercase;
    color: #ffffff;
}

.carousel_item_title {
    font-size: 0.93vw;
    line-height: 1.14vw;
    position: absolute;
    bottom: 64%;
    left: 31.5%;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #ca9800;
    padding: 0.67vw 0.88vw;
    border-radius: 0.36vw;
}

@media (max-width: 768px) {
    .carousel {
        margin-top: 75px;
    }
    .carousel_item_descr {
        top: 17%;
    }
    .carousel_item_title {
        top: 28%;
        height: 5%;
    }
}
