.industry{
    padding: 134px 0 304px 0;
    position: relative;
}

.bottom_rectangle{
    position: absolute;
    bottom: -0.1%;
    width: 100%;
}
 
.industry_inner{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    gap: 45px;
    align-items: center;
    margin: 0 auto;
    max-width: 702px;
    height: 526px;
}

.industry_link{
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    box-shadow: 2px 6px 19px -4px #094689;
    border-radius: 13px;
    text-decoration: none;
    font-weight: 400; 
    font-size: 28px;
    line-height: 40px;
    color: #0A4689;
}

.industry_link span{
    height: 80px;
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 415px;
} 
 
.industry_link:hover {
    background-color: #FFDA6B;
}

@media(max-width:767px){
    .industry{
        padding: 51px 0 81px 0;
    }
    .industry_inner{
        gap: 22.5px;
        max-width: 332px;
        height: 268px;
    }
    .industry_link{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}

