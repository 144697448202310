body{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
}

.btn-reset{
    padding: 0; 
    border: 0;
    background-color: transparent;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #013977;
}

.list-reset{
    margin: 0;
    padding: 0;
    list-style-type: none;
}


@media (min-width:1400px){
    .container{
        max-width: 1215px !important;
        padding: 0 !important;
    }     
}

@media (max-width:575px){
    .container{
        padding: 0 16px !important;
    }     
}

@media(min-width:768px){
    .collapse:not(.show){
        display: block !important;
    }
    .accordion-button::after{
        display: none;
    }
}

.slick-arrow{
    display: none !important;
}

.line{
    position: absolute;
    display: none;
    width: 35.65%;
    z-index: 1;
}

.line img{
    width: 100%;
}