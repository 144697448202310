.management_system {
    padding: 112px 0 58px 0;
}

.management_system_inner {
    display: flex;
    justify-content: space-between;
}

.management_system_text {
    max-width: 715px;
    color: #013977;
}

.management_system_title {
    font-weight: 700;
    margin-bottom: 23px;
    font-size: 18px;
    line-height: 23px;
}

.management_system_descr_2 {
    font-weight: 500;
    margin-bottom: 64px;
    font-size: 18px;
    line-height: 23px;
}

.management_system_right {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
}

.management_system_right button {
    width: 400px;
    position: relative;
}

.management_system_right button img {
    width: 100%;
    height: 100%;
}

.management_system_img_2 {
    max-width: 135px;
    max-height: 192px;
}

.management_system_img_1 {
    background: none;
    border: none;
}

@media (max-width: 1399px) {
    .management_system_right {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 50px;
    }
    .management_system_img_1,
    .management_system_img_2 {
        max-width: 300px;
        max-height: 192px;
    }
}

@media (max-width: 991px) {
    .management_system_img_1,
    .management_system_img_2 {
        max-width: 150px;
        max-height: 192px;
    }
    .management_system_right {
        display: flex;
        align-items: center;
        align-self: center;
        justify-items: center;
        column-gap: 10px;
    }
    .management_system {
        padding: 60px 0 14px 0;
    }
    .management_system_inner {
        flex-direction: column-reverse;
    }
    .management_system_title {
        font-weight: 600;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 21px;
    }
    .management_system_descr_2 {
        font-weight: 400;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 18px;
    }
}
