.vertical_slider {
	width: 214px;
}

#lightbox {
	z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*optional bg color*/
	background-color: rgba(47, 71, 180, 0.52);
	/*the following isn't necessary but will help center everything once we add buttons*/
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 20px;
	box-sizing: border-box;
	justify-content: center;
}

#lightbox img {
	width: 70%;
	object-fit: contain;
	height: 100%;
}

#lightbox button {
	position: absolute;
	top: 50%;
	transform: translate(0, 50%);
	outline: none;
	border: none;
	background: #003977;
	color: #fff;
	padding: 5px 20px;
	border-radius: 4px;
	transition: 0.3s linear;
	box-sizing: border-box;
}

#lightbox button:hover {
	border: 1px solid #003977;
	color: #003977;
	background: #fff;
}

#lightbox .prev {
	left: 20px;
}

#lightbox .next {
	right: 20px;
}

@media screen and (max-width: 768px) {
	.vertical_slider {
		width: initial;
	}
}