.company_president{
    padding: 106px 0 39px 0;
}

.company_president_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company_president_paragraph{
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 23px;
} 

.company_president_text{
    width: 65%;
}

.company_president_img{ 
    width: 30%;
}


.company_president_img:hover{ 
    cursor: pointer;
}

.company_president_img img{
    width: 100%;
}

.lightbox{
    z-index: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*optional bg color*/
	background-color: rgba(47, 71, 180, 0.52);
	/*the following isn't necessary but will help center everything once we add buttons*/
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 20px;
	box-sizing: border-box;
	justify-content: center;
}

.lightbox img {
	width: 100%;
	object-fit: contain;
	height: 100%; 
}

@media(max-width:768px){
    .company_president_inner{
        flex-direction: column-reverse;
    }
    .company_president_text{
        width: 100%;
    }
    .company_president_img{
        margin-bottom: 24px;
    }
}


@media(max-width:576px){
    .company_president{
        padding: 70px 0 35px 0;
    }
    .company_president_paragraph{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
    }
    .company_president_paragraph p:last-child{
        margin-bottom: 0;
    }
    .company_president_img{
        width: 200px;
        height: 166px;
    }
}
