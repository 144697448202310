.our_partners{
    padding: 314px 0 167px 0;
    position: relative;
}

.our_partners_inner{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(4,1fr);
}

.our_partners_img{
    width: 100%;
}

.top_rectangle{
    position: absolute;
    top: -0.2%;
    width: 100%;
}

.bottom_rectangle{
    position: absolute;
    bottom: -0.5%;
    width: 100%;
}

/* .our_partners_title{
    font-weight: 600;
    font-size: 33px;
    line-height: 43px;
    color: #013977;
    display: flex;
    justify-content: center;
}

.our_partners_line{
    margin: 39px auto 40px auto;
    max-width: 791px;
}

.our_partners_line img{
    width: 100%;
}

.our_partners_text{
    display: flex;
    justify-content: space-between;
}

.our_partners_subtitle{
    margin-bottom: 20px;
    color: #013977;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
}

.our_partners_descr{
    background: url(../assets//icons/ellipse.svg) no-repeat 0 1px;
    padding-left: 34px;
    margin-left: -34px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

@media(max-width:1399px){
    .our_partners_inner{
        padding: 0;
    }
} */

@media(max-width:767px){
    .our_partners{
        padding: 98px 0 90px 0;
    }
    /* .our_partners_text{
        display: flex;
        flex-direction: column;
        margin-top: 32px;
    }
    .our_partners_left_text{
        border-top: 0.5px solid #CA9800;
    }
    .our_partners_right_text{
        border-top: 0.5px solid #CA9800;
        border-bottom: 0.5px solid #CA9800;
    }
    .our_partners_line{
        display: none;
    }
    .our_partners_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }
    .our_partners_subtitle{
        margin: 16px 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
    }
    .our_partners_descr{
        padding-left: 34px;
        margin-left: -34px;
        font-weight: 400;
        font-size: 14px;    
        line-height: 16px;
    }
    .accordion_collapse{
        padding: 0 46px;
    } */
}


