.about_company{
    background-color: #fff;
    padding: 99px 0 244px 0;
    position: relative;
}

.bottom_rectangle{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.about_company_title{
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: #013977;
    margin-bottom: 0;
    margin-top: 30px;
}

.about_company_text{
    margin-top: 49px;
}

.about_company_descr{
    margin-top: 23px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* background: url(../assets/icons/ellipse.svg) no-repeat 0 4px;
    padding-left: 31px;
    margin-left: -31px; */
} 

.presentation{
    display: block;
    color: #0563C1;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 64px;
}

@media(max-width:575px){
    .about_company{
        padding: 60px 0;
    }
    .about_company_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }
    .about_company_text{
        margin-top: 24px;
    }
    .about_company_descr{
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background-position: 0 3px;
        background-size: 16px auto;
        margin-left: 0;
    }
    .presentation{
        font-size: 14px;
    }    
}
