.map_block {
    padding: 209px 210px 186px 0;
    position: relative;
    background-color: #0a4689;
}

.map {
    margin: 0 auto;
    max-width: 1235px;
    position: relative;
}

.map_img {
    width: 100%;
}

.map_descr {
    position: absolute;
    left: 86.5%;
    top: 27%;
    width: 25%;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    padding: 2.8%;
}

.map_descr_text {
    font-weight: 600;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    padding-left: 14%;
    line-height: 22px;
}

.map_descr_text:last-child {
    margin-bottom: 0;
}

.map_descr_text_1 {
    background: url(../assets/icons/yellow-circle.svg) no-repeat left 3px/15px;
}

.map_descr_text_2 {
    background: url(../assets/icons/green-circle.svg) no-repeat left 3px/15px;
}

.map_descr_text_3 {
    background: url(../assets/icons/red-circle.svg) no-repeat left 3px/15px;
}

.map_descr img {
    width: 100%;
}

.top_rectangle {
    position: absolute;
    top: -0.4%;
    width: 100%;
}

.bottom_rectangle {
    position: absolute;
    bottom: -0.4%;
    width: 100%;
}

.object_map {
    top: 3.66%;
    left: 40.4%;
    position: absolute;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
    color: #fff;
    text-transform: uppercase;
}

.circle {
    width: 1.21%;
}

.circle:hover {
    width: 2%;
    z-index: 2;
    transition: 0.15s;
    cursor: pointer;
}

.circle_1 {
    position: absolute;
    left: calc(432.987% / 12.35);
    top: calc(632.511% / 8.46);
    transform: translate(-50%, -50%);
}

.line_1 {
    left: calc(432.987% / 12.35);
    top: calc(632.511% / 8.46);
}

.circle_1:hover ~ .line_1 {
    display: block !important;
}

.circle_2 {
    position: absolute;
    left: calc(560.278% / 12.35);
    top: calc(650.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_2 {
    left: calc(560.278% / 12.35);
    top: calc(650.279% / 8.46);
}

.circle_2:hover ~ .line_2 {
    display: block !important;
}

.circle_3 {
    position: absolute;
    left: calc(376.278% / 12.35);
    top: calc(463.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_3 {
    left: calc(376.278% / 12.35);
    top: calc(463.279% / 8.46);
}

.circle_3:hover ~ .line_3 {
    display: block !important;
}

.circle_4 {
    position: absolute;
    left: calc(407.278% / 12.35);
    top: calc(480.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_4 {
    left: calc(407.278% / 12.35);
    top: calc(480.279% / 8.46);
}

.circle_4:hover ~ .line_4 {
    display: block !important;
}

.circle_5 {
    position: absolute;
    left: calc(391.278% / 12.35);
    top: calc(509.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_5 {
    left: calc(391.278% / 12.35);
    top: calc(509.279% / 8.46);
}

.circle_5:hover ~ .line_5 {
    display: block !important;
}

.circle_6 {
    position: absolute;
    left: calc(459.278% / 12.35);
    top: calc(637.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_6 {
    left: calc(459.278% / 12.35);
    top: calc(637.279% / 8.46);
}

.circle_6:hover ~ .line_6 {
    display: block !important;
}

.circle_7 {
    position: absolute;
    left: calc(496.278% / 12.35);
    top: calc(604.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_7 {
    left: calc(496.278% / 12.35);
    top: calc(604.279% / 8.46);
}

.circle_7:hover ~ .line_7 {
    display: block !important;
}

.circle_8 {
    position: absolute;
    left: calc(474.278% / 12.35);
    top: calc(585.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_8 {
    left: calc(474.278% / 12.35);
    top: calc(585.279% / 8.46);
}

.circle_8:hover ~ .line_8 {
    display: block !important;
}

.circle_9 {
    position: absolute;
    left: calc(530.278% / 12.35);
    top: calc(610.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_9 {
    left: calc(530.278% / 12.35);
    top: calc(610.279% / 8.46);
}

.circle_9:hover ~ .line_9 {
    display: block !important;
}

.circle_10 {
    position: absolute;
    left: calc(390.278% / 12.35);
    top: calc(692.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_10 {
    left: calc(390.278% / 12.35);
    top: calc(692.279% / 8.46);
}

.circle_10:hover ~ .line_10 {
    display: block !important;
}

.circle_11 {
    position: absolute;
    left: calc(398.278% / 12.35);
    top: calc(557.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_11 {
    left: calc(398.278% / 12.35);
    top: calc(557.279% / 8.46);
}

.circle_11:hover ~ .line_11 {
    display: block !important;
}

.circle_12 {
    position: absolute;
    left: calc(305.278% / 12.35);
    top: calc(571.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_12 {
    left: calc(305.278% / 12.35);
    top: calc(571.279% / 8.46);
}

.circle_12:hover ~ .line_12 {
    display: block !important;
}

.circle_13 {
    position: absolute;
    left: calc(589.278% / 12.35);
    top: calc(331.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_13 {
    left: calc(589.278% / 12.35);
    top: calc(331.279% / 8.46);
}

.circle_13:hover ~ .line_13 {
    display: block !important;
}

.circle_14 {
    position: absolute;
    left: calc(880% / 12.35);
    top: calc(570% / 8.46);
    transform: translate(-50%, -50%);
}

.line_14 {
    left: calc(880% / 12.35);
    top: calc(570% / 8.46);
}

.circle_14:hover ~ .line_14 {
    display: block !important;
}

.circle_15 {
    position: absolute;
    left: calc(450% / 12.35);
    top: calc(750% / 8.46);
    transform: translate(-50%, -50%);
}

.line_15 {
    left: calc(450% / 12.35);
    top: calc(750% / 8.46);
}

.circle_15:hover ~ .line_15 {
    display: block !important;
}

.circle_16 {
    position: absolute;
    /* left: calc(589.278%/12.35);
    top: calc(600%/8.46); */
    left: calc(578.278% / 12.35);
    top: calc(655.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_16 {
    /* left: calc(589.278% / 12.35);
    top: calc(600% / 8.46); */
    left: calc(578.278% / 12.35);
    top: calc(655.279% / 8.46);
}

.circle_16:hover ~ .line_16 {
    display: block !important;
}

.circle_17 {
    position: absolute;
    left: calc(530.278% / 12.35);
    top: calc(630.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_17 {
    left: calc(530.278% / 12.35);
    top: calc(630.279% / 8.46);
}

.circle_17:hover ~ .line_17 {
    display: block !important;
}

.circle_18 {
    position: absolute;
    left: calc(500% / 12.35);
    top: calc(585% / 8.46);
    transform: translate(-50%, -50%);
}

.line_18 {
    left: calc(500% / 12.35);
    top: calc(585% / 8.46);
}

.circle_18:hover ~ .line_18 {
    display: block !important;
}

.circle_19 {
    position: absolute;
    left: calc(420.278% / 12.35);
    top: calc(557.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_19 {
    left: calc(420.278% / 12.35);
    top: calc(557.279% / 8.46);
}

.circle_19:hover ~ .line_19 {
    display: block !important;
}

.circle_20 {
    position: absolute;
    left: calc(398.278% / 12.35);
    top: calc(587.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_20 {
    left: calc(398.278% / 12.35);
    top: calc(587.279% / 8.46);
}

.circle_20:hover ~ .line_20 {
    display: block !important;
}

.circle_21 {
    position: absolute;
    left: calc(415.278% / 12.35);
    top: calc(580.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_21 {
    left: calc(415.278% / 12.35);
    top: calc(580.279% / 8.46);
}

.circle_21:hover ~ .line_21 {
    display: block !important;
}

.circle_22 {
    position: absolute;
    left: calc(450% / 12.35);
    top: calc(660% / 8.46);
    transform: translate(-50%, -50%);
}

.line_22 {
    left: calc(450% / 12.35);
    top: calc(660% / 8.46);
}

.circle_22:hover ~ .line_22 {
    display: block !important;
}

.circle_23 {
    position: absolute;
    left: calc(390.278% / 12.35);
    top: calc(707.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_23 {
    left: calc(390.278% / 12.35);
    top: calc(707.279% / 8.46);
}

.circle_23:hover ~ .line_23 {
    display: block !important;
}

.circle_24 {
    position: absolute;
    left: calc(330.278% / 12.35);
    top: calc(571.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_24 {
    left: calc(330.278% / 12.35);
    top: calc(571.279% / 8.46);
}

.circle_24:hover ~ .line_24 {
    display: block !important;
}

.circle_25 {
    position: absolute;
    left: calc(300.278% / 12.35);
    top: calc(588.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_25 {
    left: calc(300.278% / 12.35);
    top: calc(588.279% / 8.46);
}

.circle_25:hover ~ .line_25 {
    display: block !important;
}

.circle_26 {
    position: absolute;
    left: calc(330.278% / 12.35);
    top: calc(588.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_26 {
    left: calc(330.278% / 12.35);
    top: calc(588.279% / 8.46);
}

.circle_26:hover ~ .line_26 {
    display: block !important;
}

.circle_27 {
    position: absolute;
    left: calc(345.278% / 12.35);
    top: calc(588.279% / 8.46);
    transform: translate(-50%, -50%);
}

.line_27 {
    left: calc(345.278% / 12.35);
    top: calc(588.279% / 8.46);
}

.circle_27:hover ~ .line_27 {
    display: block !important;
}

.circle_28 {
    position: absolute;
    left: calc(500% / 12.35);
    top: calc(730% / 8.46);
    transform: translate(-50%, -50%);
}

.line_28 {
    left: calc(500% / 12.35);
    top: calc(730% / 8.46);
}

.circle_28:hover ~ .line_28 {
    display: block !important;
}

.circle_29 {
    position: absolute;
    left: calc(515% / 12.35);
    top: calc(730% / 8.46);
    transform: translate(-50%, -50%);
}

.line_29 {
    left: calc(515% / 12.35);
    top: calc(730% / 8.46);
}

.circle_29:hover ~ .line_29 {
    display: block !important;
}

.circle_30 {
    position: absolute;
    left: calc(528% / 12.35);
    top: calc(745% / 8.46);
    transform: translate(-50%, -50%);
}

.line_30 {
    left: calc(528% / 12.35);
    top: calc(745% / 8.46);
}

.circle_30:hover ~ .line_30 {
    display: block !important;
}

.object_name {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    position: absolute;
    left: 21%;
    right: 0;
    bottom: 7%;
    text-transform: uppercase;
    text-align: center;
}

@media (max-width: 1199px) {
    .map_block {
        padding-right: 190px;
    }
}

@media (max-width: 991px) {
    .object_name {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
    }
    .map_block {
        padding: 139px 160px 116px 0;
    }
    .object_map {
        font-size: 25px;
    }
    .map_descr_text {
        font-size: 13px;
        line-height: 20px;
    }
    .map_descr_text_1 {
        background: url(../assets/icons/yellow-circle.svg) no-repeat left 4px/12px;
    }

    .map_descr_text_2 {
        background: url(../assets/icons/green-circle.svg) no-repeat left 4px/12px;
    }

    .map_descr_text_3 {
        background: url(../assets/icons/red-circle.svg) no-repeat left 4px/12px;
    }
}

@media (max-width: 767px) {
    .object_name {
        font-size: 8px;
        line-height: 10px;
    }
    .map_block {
        padding: 81px 130px 166px 0;
    }
    .object_map {
        top: 0;
        left: 30%;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }
    .map_descr {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        left: 30%;
        top: 110%;
        width: 350px;
        padding: 2%;
    }
    .map_descr_text {
        text-transform: lowercase;
    }
    .map_descr_text::first-letter {
        text-transform: uppercase;
    }
    .map_descr_text {
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 0;
        padding-left: 0;
        text-align: center;
    }
    .map_descr_text_1 {
        background: url(../assets/icons/yellow-circle.svg) no-repeat left 2px/10px;
    }

    .map_descr_text_2 {
        background: url(../assets/icons/green-circle.svg) no-repeat left 2px/10px;
    }

    .map_descr_text_3 {
        background: url(../assets/icons/red-circle.svg) no-repeat left 2px/10px;
    }
}

@media (max-width: 575px) {
    .map_block {
        padding: 81px 100px 136px 0;
    }
    .map_descr {
        display: flex;
        flex-direction: row;
        left: 10%;
        top: 110%;
        width: 105%;
        padding: 2%;
    }
    .map_descr_text {
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 0;
        padding-left: 4%;
        text-align: center;
    }
}

@media (max-width: 488px) {
    .map_descr {
        display: flex;
        flex-direction: row;
        left: 25%;
        top: 115%;
        width: 85%;
    }
}

@media (max-width: 425px) {
    .map_block {
        padding: 81px 70px 106px 0;
    }
    .top_rectangle {
        position: absolute;
        top: -0.3%;
        width: 100%;
    }
    .bottom_rectangle {
        position: absolute;
        bottom: -0.3%;
        width: 100%;
    }
    .map_descr {
        display: flex;
        flex-direction: row;
        left: 13%;
        top: 110%;
        width: 100%;
    }
}

@media (max-width: 375px) {
    .map_block {
        padding: 81px 65px 126px 0;
    }
    .object_map {
        top: -10%;
        left: 20%;
    }
    .map_descr {
        left: 3%;
        top: 115%;
        width: 115%;
    }
}
