.history_descr {
    padding: 116px 0 195px 0;
    position: relative;
}

.bottom_rectangle{
    position: absolute;
    bottom: -0.1%;
    width: 100%;
}

.history_descr_text {
    margin-top: 38px;
}

.history_descr_paragraph {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
}

.history_descr_paragraph span {
    font-weight: 700;
}

@media(max-width:576px) {
    .history_descr {
        padding: 20px 0 70px 0;
    }

    .history_descr_paragraph {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-shadow: 2px 1px 40px rgba(0, 0, 0, 0.25);
    }
    .history_descr_paragraph span {
        font-weight: 600;
    }
}
