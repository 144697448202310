.historical_dates{
    background-color: #0A4689;
    padding: 258px 0 217px 0;
    position: relative;
    color: #fff;
}

.top_rectangle{
    position: absolute;
    top: -0.2%;
    width: 100%;
}

.bottom_rectangle{
    position: absolute;
    bottom: -0.2%;
    width: 100%;
}

.historical_dates_title{
    font-weight: 700;
    font-size: 33px;
    line-height: 43px;
    margin-bottom: 68px;
    margin-left: 2px;
}

.historical_dates_paragraph{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    /* background: url(../assets//icons//ellipse.svg) no-repeat 0 5px;
    padding-left: 40px; */
    margin-bottom: 0;
}

.historical_dates_paragraph p:last-child{
    margin-bottom: 0;
}

/* year Of formation */

.yearOfFormation {
    padding: 100px 0 70px 0;
}

.yearOfFormationInner{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.yearOfFormationInner p{  
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.yearOfFormation p span{
    font-weight: 700;
}

.yearOfFormationImg img{
    width: 100%;
}

@media(max-width:576px){
    .historical_dates{
        background:
        url(../assets/images/bottom-rectangle.svg) bottom/100% no-repeat,#0A4689;
        padding: 90px 0 35px 0;
        position: relative;
        color: #fff;
    }
    
    .historical_dates_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 32px;
    }
    .historical_dates_paragraph{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}

