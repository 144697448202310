.partners_descr{
    background-color:#F0F7FC;
    padding: 131px 0 80px 0;
}

.partners_descr_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partners_descr_text{
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}

.partners_descr_paragraph{
    margin-bottom: 46px;
}

.partners_descr_img{
    max-width: 360px;
}

.partners_descr_img img{
    width: 100%;
}

@media(max-width:991px){
    .partners_descr_paragraph{
        margin-bottom: 23px;
    }
}

@media(max-width:767px){
    .partners_descr{
        padding: 48px 0 22px 0;
    }
    .partners_descr_inner{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
    }
    .partners_descr_img{
        max-width: 224px;
    }
    .partners_descr_text{
        margin-top: 24px;
    }
    .partners_descr_text{
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}
