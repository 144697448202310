.resources{
    padding: 106px 0 241px 0;
    position: relative;
}

.bottom_rectangle{
    position: absolute;
    bottom: 0;
    width: 100%;
} 
 
.resources_inner{
    padding: 0 45px;
} 

.resources_main{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}
 
.resources_main:first-child{
    margin-top: 0;
}

.resources_img{
    height: 600px;
}

.resources_img img{
    width: 100%;
    height: 100%;
}

.resources ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.resources_title{
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: #013977;
}

.resources_subtitle{
    font-weight: 500;
    font-size: 22px;
    line-height: 23px;
    color: #013977;
    margin-top: 75px;
}

.resources_info{
    margin-top: 50px;
}


.resources_descr{
    margin-top: 23px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* background: url(../assets/icons/ellipse.svg) no-repeat 0 1px;
    padding-left: 40px; */
}

.resources_programs{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.slider{
    margin: 90px auto 0 auto;
    max-width: 1110px;
}

.slider img{
    width: 100%;
}

@media(max-width:991px){
    .resources_inner{
        padding: 0;
    }
    .resources_img{
        height: 400px;
    }
}

@media(max-width:767px){
    .resources_img{
        height: 200px;
    }
    .resources{
        padding: 30px 0 99px 0;
    }
    .resources_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
    }
    .resources_programs{
        flex-direction: column;
        margin-top: 50px;
    }
    .resources_subtitle{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-top: 32px;
        margin-bottom: 0;
    }
    .resources_descr{
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background-size: 16px auto;
        padding-left: 24px;
    }
    .resources_programs{
        margin-top: 0;
        margin-bottom: 32px;
    }
    .resources_info{
        margin-top: 0;
    }
}
