.hero {
    position: relative;
    height: 42vw;
}

.hero_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero_bottom {
    position: absolute;
    bottom: -1%;
    width: 100%;
    z-index: 1;
}

.blue_line {
    position: absolute;
    left: 75.3%;
    width: 5.83%;
    height: 100%;
}

.hero_left {
    position: absolute;
    left: 0;
    top: 0;
    height: 42%;
}

.hero_lines {
    position: absolute;
    z-index: 1;
    left: 0;
    top: -8%;
    height: 46%;
}

.hero_text_container {
    position: absolute;
    z-index: 1;
    top: 41%;
    left: 59.85%;
    width: 36.6%;
    height: 100%;
    text-align: center;
}

.hero_text {
    text-transform: uppercase;
    transform: rotate(270deg);
    color: #fff;
    font-weight: 700;
    font-size: 1.75vw;
    margin: 0;
}

@media (max-width: 768px) {
    .hero {
        margin-top: 75px;
        overflow: hidden;
    }
}
