.department{
    padding: 225px 0 180px 0;
    background-color: #F0F7FC;
    position: relative;
}

.bottom_rectangle{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.department_title{
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #013977;
    text-transform: uppercase;
    margin-bottom: 68px;
}

.department_item{
    margin-top: 70px;
}

.slider{
    margin-top: 96px;
    margin-bottom: 99px;
}

.department_subtitle{
    line-height: 29px;
    color: #013977;
    font-weight: 500;
    font-size: 22px;
}

.department_subtitle_2{
    color: #013977;
    margin-top: 22px;
    line-height: 23px;
    font-size: 18px;
    font-weight: 500;
}

.department_descr{
    margin-top: 22px;
    line-height: 23px;
    font-size: 18px;
    font-weight: 500;
    /* background: url(../assets/icons/ellipse.svg) no-repeat 0 4px;
    padding-left: 31px;
    margin-left: -31px; */
}

@media(max-width:1199px){
    .department_descr{
        margin-left: 0;
    }
}

@media(max-width:767px){
    .department{
        padding: 101px 0 70px 0;
        background-color: #F0F7FC;
        position: relative;
    }
    .department_title{
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 24px;
        text-align: center;
    }
    
    .department_item{
        margin-top: 32px;
    }
    .slider{
        margin-top: 32px;
    }
    .department_subtitle{
        line-height: 22px;
        font-weight: 500;
        font-size: 16px;
    }
    .department_subtitle_2{
        margin-top: 16px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 400;
    }
    .department_descr{
        margin-top: 16px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 400;
        background-size: 16px auto;
        background-position: 0 3px;
        padding-left: 24px;
    }
}