.cards {
    padding: 106px 0 278px 0;
    position: relative;
    background-color: #f0f7fc;
}

.top_rectangle {
    position: absolute;
    top: -0.2%;
    width: 100%;
}

.bottom_rectangle {
    position: absolute;
    bottom: -0.5%;
    width: 100%;
}

.cards_title {
    font-weight: 600;
    font-size: 33px;
    line-height: 43px;
    text-align: center;
    color: #013977;
    margin-bottom: 70px;
}

.mountain {
    height: 100%;
    padding: 20px 20px 0px 20px;
}
.mountain img {
    height: 100%;
}
.cards_inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr;
    row-gap: 38px;
    column-gap: 45px;
    margin: 0 auto;
    max-width: 955px;
}

.cards_link {
    box-shadow: 2px 6px 19px -4px #094689;
    border-radius: 13px;
    height: 190px;
    padding: 31px 0 45px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #004d92;
    text-align: center;
}

.cards_link img {
    max-width: 100%;
}

.cards_link span {
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cards_link:hover {
    background-color: #ffda6b;
}

@media (max-width: 991px) {
    .cards_link {
        height: 160px;
        padding: 20px 0 30px 0;
    }
}

@media (max-width: 767px) {
    .cards {
        padding: 50px 0 72px;
    }
    .cards_title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 35px;
    }
    .cards_inner {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4, 1fr);
        row-gap: 24px;
        column-gap: 32px;
        max-width: 295px;
    }
    .cards_link {
        height: 122px;
        padding: 12px 0 20px 0;
        line-height: 14px;
        font-size: 13px;
    }
    .cards_link span {
        max-width: 103px;
    }
    .mountain {
        height: 70%;
        width: 72px;
        padding: 5px 0px 0px 0px !important;
    }
    .metallurgy {
        width: 45px;
    }
    .architecture {
        width: 33px;
    }
    .transport {
        width: 37px;
    }
    .electrical {
        width: 41px;
    }
    .plumbing {
        width: 46px;
    }
    .economy {
        width: 41px;
    }
    .automation {
        width: 51px;
    }
}
